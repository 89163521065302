exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-bounties-contentful-bounties-id-tsx": () => import("./../../../src/pages/bounties/{contentfulBounties.id}.tsx" /* webpackChunkName: "component---src-pages-bounties-contentful-bounties-id-tsx" */),
  "component---src-pages-bounties-submit-report-contentful-bounties-id-tsx": () => import("./../../../src/pages/bounties/submit-report/{contentfulBounties.id}.tsx" /* webpackChunkName: "component---src-pages-bounties-submit-report-contentful-bounties-id-tsx" */),
  "component---src-pages-bounties-tsx": () => import("./../../../src/pages/bounties.tsx" /* webpackChunkName: "component---src-pages-bounties-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-submit-bip-tsx": () => import("./../../../src/pages/submit-bip.tsx" /* webpackChunkName: "component---src-pages-submit-bip-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

